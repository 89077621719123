@import '../styles/helpers';

.footer {
  padding-bottom: 33px;
  padding-top: 56px;
  border-top: 1px solid;
  @include setProperty(border-image, (linear-gradient(to right, rgba(30, 30, 30, 0.4), #1E1E1E, rgba(30, 30, 30, 0.4)) 10), (linear-gradient(to right, rgba(234, 234, 234, 0.4), #EAEAEA, rgba(234, 234, 234, 0.4)) 10));
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  position: relative;

  @include media(mobile) {
    padding-bottom: 57px;
  }

  @include media(tablet) {
    padding-top: 72px;
  }

  @include media(desktopAll) {
    padding-top: 100px;
  }

  @include media(desktopHuge) {
    padding-top: 120px;
  }

  &__logo {
    margin-bottom: 64px;

    @include media(mobile) {
      margin: 0 auto 64px;
    }

    @include media(portrait) {
      margin-bottom: 48px;
    }

    @include media(desktopHuge) {
      width: 167px !important;
    }
  }

  &__main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 auto 64px;
    width: 1296px;

    @include media(mobile) {
      width: 100%;
      padding: 0 24px;
      display: grid;
      grid-template-columns: 1fr;
    }

    @include media(portrait) {
      width: 100%;
      padding: 0 60px;
      display: block;
    }

    @include media(landscape) {
      width: 100%;
      padding: 0 48px;
      display: block;
    }

    @include media(desktopAll) {
      display: block;
    }

    @include media(desktopSmall) {
      width: 100%;
      padding: 0 72px;
    }

    @include media(desktopHuge) {
      width: 1760px;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 120px;
    }
  }

  &__menu {
    display: flex;
    gap: 80px;

    @include media(mobile) {
      display: grid;
      justify-content: center;
      align-items: flex-start;
      grid-template-columns: 1fr;
      gap: 100px;
      width: 100%;
      text-align: center;
    }

    @include media(portrait) {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(2, auto);
      gap: 60px 20px;
      width: 100%;

      div:first-child {
        grid-column: 1 / 3;

        ul {
          display: grid;
          grid-template-columns: repeat(3, auto);
          justify-content: space-between;
          gap: 20px;

          .block__item {
            margin-top: 0;
          }
        }
      }
    }

    @include media(landscape) {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(3, auto);
      gap: 60px 20px;
      width: 100%;

      div:first-child {

        ul {
          display: grid;
          grid-template-columns: repeat(2, auto);
          justify-content: space-between;
          gap: 20px 56px;

          .block__item {
            margin-top: 0;
          }
        }
      }
    }

    @include media(desktopSmall) {
      gap: 60px;
    }

    @include media(desktopAll) {
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: space-between;

      div:first-child {

        ul {
          display: grid;
          grid-template-columns: repeat(3, auto);
          justify-content: space-between;
          gap: 20px 56px;

          .block__item {
            margin-top: 0;
          }
        }
      }
    }

    @include media(desktopHuge) {
      gap: 120px;

      div:first-child {

        ul {
          grid-template-columns: 1fr;
          gap: 20px;

          .block__item {
            margin-top: 0;
          }
        }
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;

    @include media(tablet) {
      justify-content: flex-start;
      margin-top: var(--margin-sm);
    }

    @include media(desktopAll) {
      justify-content: flex-start;
      margin-top: var(--margin-sm);
    }
  }

  &__info {
    flex-shrink: 0;

    @include media(portrait) {
      display: grid;
      //grid-template-columns: 141px 1fr;
      gap: 0 64px;
      align-items: flex-start;
    }

    @include media(mobile) {
      display: grid;
      grid-template-columns: 1fr;
      align-items: flex-start;
      margin-right: 0;
      order: 2;
      margin-top: 100px;
    }

    &__block {
      margin-bottom: 64px;

      @include media(mobile) {
        text-align: center;
      }

    }

    &__text {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      margin-bottom: 16px;
      font-family: 'KharkivTone', sans-serif;

      @include media(desktopHuge) {
        font-size: 16px;
        line-height: 1;
      }

      a {
        color: inherit;

        &:hover {
          @include setProperty(color, var(--primary-500), var(--primary-900));

          svg {
            opacity: 1;

            path {
              @include setProperty(fill, var(--primary-500), var(--primary-900));
            }
          }
        }
      }
    }
  }

  &__telegram {
    transition: none;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    text-align: center;
    font-size: 12px;
    line-height: 1;

    &:hover span {
      text-decoration: none;
    }

    svg {
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }

    span {
      text-decoration: underline;
      margin-left: 8px;
    }
  }
}

.block {

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 48px;
    display: block;
    letter-spacing: 0.2px;

    @include media(desktopHuge) {
      font-size: 24px;
    }

    @include media(mobile) {
      margin-bottom: 32px;
    }
  }

  &__item {

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__link {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-weight: 300;
    font-size: 12px;
    line-height: 1;

    @include media(desktopHuge) {
      font-size: 16px;
    }

    @include media(tablet) {
      font-size: 11px;
    }

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1296px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1;
  font-weight: 300;
  gap: 12px;

  @include media(desktopHuge) {
    width: 1760px;
  }

  @include media(desktopSmall) {
    width: 100%;
    padding: 0 72px;
  }

  @include media(landscape) {
    width: 100%;
    padding: 0 48px 40px;
  }

  @include media(portrait) {
    width: 100%;
    padding: 0 60px 40px;
  }

  @include media(mobile) {
    width: 100%;
    flex-direction: column-reverse;
  }

  &__link {
    display: inline-block;
    @include setProperty(color, var(--secondary-50), var(--secondary-400));

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
      text-decoration: underline;
    }

    &:active {
      @include setProperty(color, var(--primary-200), var(--primary-500));
    }

    &:not(:first-child) {
      position: relative;

      &:before {
        content: '-';
        pointer-events: none;
        margin-right: 8px;
        margin-left: 8px;
        display: inline-block;
        @include setProperty(color, var(--secondary-50), var(--secondary-400));
      }
    }
  }
}

.addresses {
  display: grid;
  align-items: flex-start;
  gap: var(--margin-xxl);

  @include media(tablet) {
    gap: var(--margin-xl) var(--margin-md);
    margin-bottom: 80px;
  }

  @include media(portrait) {
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
  }

  @include media(landscape) {
    grid-template-columns: repeat(4, auto);
    justify-content: space-between;
  }

  @include media(desktopAll) {
    grid-template-columns: repeat(5, auto);
    justify-content: space-between;
    margin-bottom: 72px;
    gap: 16px;
  }

  @include media(desktopSmall) {
    margin-bottom: 60px;
  }

  @include media(desktopHuge) {
    grid-template-columns: 1fr;
    gap: 40px;
    margin-bottom: 0;
  }
}

.address {
  display: grid;
  text-align: center;

  @include media(tablet) {
    text-align: left;
    max-width: 250px;
  }

  @include media(desktopAll) {
    text-align: left;
    max-width: 220px;
  }

  .info {
    display: grid;
    gap: 8px;
  }

  &__city {
    font-family: 'KharkivTone', sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.228px;
    margin-bottom: 12px;
    position: relative;

    @include media(tablet) {
      padding-left: 30px;
    }

    @include media(desktopAll) {
      padding-left: 30px;
    }
  }

  .flag {
    width: 18px;
    margin-right: 12px;
    vertical-align: middle;

    @include media(tablet) {
      position: absolute;
      left: 0;
    }

    @include media(desktopAll) {
      position: absolute;
      left: 0;
    }

    img {
      width: 100%;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.24px;

    @include media(tablet) {
      padding-left: 30px;
    }

    @include media(desktopAll) {
      padding-left: 30px;
    }
  }
}

a.address__text {

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &:active {
    @include setProperty(color, var(--primary-300), var(--primary-700));
  }
}

.socialsBlock {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--margin-lg);

  @include media(portrait) {
    grid-column: 2 / 4;
    display: block;
  }

  @include media(landscape) {
    grid-column: 1 / 5;
    display: block;
  }

  @include media(desktopAll) {
    display: block;
  }
}
